.draft-editor {
    margin-right: 80px;
    padding: 34px;
    box-shadow: 0px 2px 5px -1px #0000001c;

    .next {
        border: 2px solid #0072ff;
        background: #0072ff;
        color: #fff;

        &:disabled {
            pointer-events: none; 
            cursor: default; 
        }
    }

    .label {
        color: rgba(0, 0, 0, 0.54);
        padding: 0;
        font-size: 0.75rem;
        font-family: Commissioner,sans-serif;
        font-weight: 400;
        line-height: 1;
    }

    label {
        white-space: nowrap;
    }
}

.disable { 
    pointer-events: none; 
    cursor: default; 
}

.bedroom {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: "Open Sans", sans-serif;
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.7);
    border-bottom: 1px solid #e8e8e8;
}

.publish {
    background: #c0ffc0;
    color: #000;
    border-color: #00c400;

    &:disabled {
        pointer-events: none; 
        cursor: default; 
    }
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

.payout-sheet {
    font-family: 'Montserrat', sans-serif;
    padding: 1.25rem 3.125rem;
    font-size: 1.125rem;
    font-weight: 500;

    .text-small {
        font-size: 0.6rem;
    }

    .text-large {
        font-size: 1.6rem;
    }

    .text-medium {
        font-size: 0.754rem;
    }

    .text-bold {
        font-weight: 700;
    }

    .date-group {
        display: flex;
        background-color: #E7E7E7 ;
        border-radius: 0.75rem;
        align-items: baseline;
        width: 18rem;

        button {
            font-family: 'Montserrat',sans-serif;
            font-size: 0.754rem;
            border: none;
            background-color: transparent;
            cursor: pointer;
            color: #787878;


            
        }

        .first-btn {
            &:hover {
                background-color: #787878a2;
                border-radius: 0.75rem 0 0 0.75rem;
            }
        }

        .second-btn {
            &:hover {
                background-color: #787878a2;
                border-radius: 0 0.75rem 0.75rem 0;
            }
        }

        .active {
            color: black;
            font-weight: 900;
        }
    }

    .btn {
        color: #fff;
        border: none;
        font-size: 0.754rem;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        border-radius: 0.225rem;
        padding: 0.525rem 0.713rem;
        max-width: 10rem;
        text-align: center;
        cursor: pointer;
        text-decoration: none;
        display: block;
    }
    
    .btn-primary {
        background-color: #0500FF;

        &:hover {
            background-color: #0400ffa2;
        }
    }

    .btn-secondary {
        background-color: #787878;

        &:hover {
            background-color: #787878a2;
        }
    }


    .table {
        font-size: 10px;
        color: #000;
        overflow: auto;
        position: relative;
        -webkit-user-select: none;
        user-select: none;
        border: 1px solid #CACACA;
        max-height: calc(100vh - 85px);


        &-header {
            display: flex;
            flex-wrap: nowrap;
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            z-index: 200;

            &-cell {
                flex: 0 0 10%;
                background-color: #CACACA;
                padding: 8px;
                text-align: center;
                font-weight: bold;
                vertical-align: middle;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                border-top: 1px solid #CACACA;
                border-bottom: 1px solid #CACACA;
                border-right: 1px solid white;
                border-left: 1px solid white;

                &:first-of-type {
                    border-left: 1px solid #CACACA;
                }

                &:last-of-type {
                    border-right: 1px solid #CACACA;
                }
            }

            
        }

        &-row {
            display: flex;
            flex-wrap: nowrap;

            &-cell {
                flex: 0 0 10%;
                position: relative;
                border: 1px solid #CACACA;
                cursor: pointer;
                white-space: nowrap;
                text-overflow: ellipsis;
                //display: inline-block;
                overflow: hidden;
                padding: 8px;
                box-sizing: border-box;

                &:hover {
                    border: 1px solid #0500FF;
                    background-color: #cacaca6e;
                    font-weight: bold;
                }

                abbr {
                    text-decoration: none;
                    border-bottom: none;
                }
            }
        }

    }

    .sticky {
        position: -webkit-sticky;
        position: sticky;
        top:0;
    }
}

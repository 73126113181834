.table-header {
    .booking-id {
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
        font-weight: bold;
        font-family: 'Open Sans';
        color: #0072ff;
        text-transform: uppercase;
        border: 1px dotted;
        padding: 2px;
        text-align: center;
    }
} 

.tbl-cell:hover {
    background: aliceblue;
}

.booking-status-checkout {
    background: rgb(255, 145, 0);
    text-align: center;
    padding: 2px;
    border-radius: 4px;
    color: white !important;
}

.booking-status-checkin {
    background: rgb(2, 36, 148);
    text-align: center;
    padding: 2px;
    border-radius: 4px;
    color: white !important;
}

.booking-status-hosting {
    background: #fab245;
    text-align: center;
    padding: 2px;
    border-radius: 4px;
    color: white !important;
}
/* CSS for all static components */

.not-found {
    padding: $fixed-padding;
    .not-found-content {
        margin-top: 50px;
        margin-bottom: 50px;

        img {
            height: 400px;
            width: 100%;
            padding: 50px;
        }

        p {
            text-align: center;
            font-weight: bold;
            font-size: 1.6rem;
            font-family: $font-h;
        }
    }
}

.loading {
    padding: $fixed-padding;
    text-align: center;
}
.listings-count-container {
    padding: 20px;
    border-radius: 6px;
    background: #fafafa;
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.10);
    height: 100%;

    .listings-count {
        font-size: 2rem;
        font-family: $font-h;
        font-weight: bold;


    }

    .caption {
        font-family: $font-p;
    }
}

.search-box {
    margin-top: 50px;
    margin-bottom: 50px;
    .input-cover {
        border: 2px solid $second;
        border-radius: 3px;
        position: relative;
        .icon {
            padding: 10px 0px;
            display: flex;
            justify-content: center;
        }
        .input-search {
            width: 100%;
            padding: 10px;
            font-size: 1.1rem;
            border: none;
            height: 100%;
            font-family: $font-p;
        }

        .suggestions {
            position: absolute;
            width: 100%;
            margin-top: 2px;
            box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.1);
            background: #fff;
            z-index: 9;

            a {
                text-decoration: none;
                
            }

            .empty-property-search {
                padding: 30px;
                
                img {
                    height: 100px;
                    object-fit: fill;
                }

                p {
                    font-size: 0.9rem;
                    font-family: $font-p;
                    color: $second;
                    text-align: center;
                }
            }

            .search-suggestion-item-wrapper {
                padding-left: 10px;
                padding-right: 10px;
            }

            .search-suggestion-item-wrapper:hover {
                background: aliceblue;
            }

            .search-suggestion-items {
                padding-top: 10px;
                padding-bottom: 10px;
                border-bottom: 1px solid #0000000f;
            }

            img {
                border-radius: 6px;
                width: 100%;
                height: 100%;
                object-fit: cover;

            }

            .search-suggestion-item-img, .search-suggestion-item-title {
                padding: 10px;
            }

            .search-suggestion-item-title {
                font-size: 0.9rem;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-family: $font-p;
                color: $second;
            
            }
        }
    }
}


.listing-item-img {
    img {
        width: 100%;
        border-radius: 6px;
    }
    padding: 10px;

}

.listing-item-listing {
    padding: 12px;
    font-size: 1.1rem;
    font-family: $font-p;
}

.title-head-item {
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}


.table-header {
    div {
        color: $second;
    }

    a {
        text-decoration: none;
    }
}

.loading-listing {
    padding: 20px;
    text-align: center;
}


.manage-button {
    padding: 34px;
    box-shadow: 0px 2px 5px -1px #0000001c;


    .manage-buttons {
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid #ececec;

        a {
            text-decoration: initial;
            color: initial;
        }

        .box-button {
            width: 120px;
            height: 100px;
            box-shadow: 0 0px 0px 1px #00000045;
            border-radius: 4px;
            padding: 4px;
            font-family: 'Commissioner';
            color: #757575;
            background: #ffffff;
            font-size: 0.7rem;
            text-align: center;
            cursor: pointer;

            &:hover {
                box-shadow: 0 0px 1px 1px  #00000045;
            }

            .img-icon {
               
                margin: 6px;
                img {
                    width: 60px;
                    height: auto;
                }
            }


        }

        
    }

}

.wrap-gst {
    width: 500px;
    margin: 20px;
}

.trial-ended {
    width: fit-content;
    padding: 12px 10px;
    text-align: center;
    margin: 0 auto;
    border: 1px solid #9d7402;
    color: #9d7402;
    border-radius: 3px;
    font-family: 'Commissioner';
    font-size: 0.7rem;
}
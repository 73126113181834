.root-container {
    .header-container {
        padding: $fixed-padding;
        box-shadow: 0px 2px 5px -1px #0000000f;
    }


    .logo {
        font-family: $font-h;
        font-weight: bold;
        font-size: 1.75rem;
    }

    .logo-caption {
        font-size: 0.75rem;
        line-height: .3rem;
        letter-spacing: 0.07rem;
        color: $blue;
        font-weight: bold;
        font-family: $font-h;
        transform: translateX(4.8rem);
    }
}

.MuiPaper-elevation2 {
    box-shadow: 0px -2px 0px -2px rgba(0,0,0,0.1), 0px 0px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

.notification-box {
    position: relative;
    width: 100%;
    padding: 10px 20px;

    .title-notification, .notification-item {
        margin-top: 10px;
        margin-bottom: 10px;

        .notification-item-elem {
            width: 100%;

            .icon-item-notification {
                transform: translateX(10px);
            }

            .time-ago-notification-item-elem {
                font-size: 0.9rem;
                color: #808080;
            }

            .user {
                font-weight: bold;
            }

            .img-notification-item {
                width: 100%;
                height: 35px;
                border-radius: 6px;
                transform: translateX(-10px);
            }

            p {
                white-space: break-spaces;
            }
        }
        
    }
}

.list-menu {
    .list-menu-item {
        text-decoration: none;
        position: relative;
        font-family: $font-p;
        color: $second;
        font-size: 0.9rem;
        font-weight: bold;
        padding: 0px 4px;
    }

    .list-menu-item:hover::after {
        
            content: "";
            display: block;
            width: 100%;
            height: 4px;
            position: absolute;
            bottom: -25px;
            z-index: 999;
            background: $blue;
        
    }

    .list-menu-item-active::after {
        content: "";
        display: block;
        width: 100%;
        height: 4px;
        position: absolute;
        bottom: -25px;
        z-index: 999;
        background: $blue;
    }
}

.dropdown-link {
    text-decoration: none;
    color: #000000cb;
}
.auth-wrapper {
    padding: $fixed-padding;

    .login-form {
        margin-top: 50px;
        margin-bottom: 50px;
        .login-container {
            padding: 15px;
            box-shadow: 0px 2px 5px -1px #0000001c;
            border-radius: 5px;
        }
        .login-title {
            font-size: 1.5rem;
            color: $blue;
            font-family: $font-h;
            margin-bottom: 15px;
            border-bottom: 1px solid;
            padding-bottom: 5px;
            font-weight: bold;
        }

        .form-elements {
            margin-bottom: 20px;
        }
    }
}
.legend-wrapper {
    padding: 12px;
    border-left: 1px solid rgba(0, 0, 0, 0.12);

    .legend {
        margin-top: 10px;
        margin-bottom: 10px;
        .tile {
            h2 {
                display: flex !important;
                align-items:center !important;
            }
        }
        .caption {
            font-family: $font-p;
            font-size: 0.8rem;
        }
    }
}
.internal-rating {
    p {
        font-size: 0.9rem;
        font-family: $font-p;
        color: $second;
        font-weight: bold;
        transform: translateX(10px)
    }

    .MuiRating-sizeLarge {
        font-size: 2.875rem;
    }
}

.listing-title-text {
    padding-right: 30px;
    h2 {
        font-size: 3rem;
    }
}
.change-listing {
    margin-top: 20px;
    margin-bottom: 20px;

    .change-listing-btn {
        padding: 5px;
        width: fit-content;
        font-weight: bold;
        font-family: $font-h;
        display: flex;
        align-items: center;
    }
}

.contentarea {
    .editview-area {
        padding: 30px 5px;
        margin-top: 10px;
        margin-bottom: 10px;

        .details-box {
            margin-bottom: 20px;
        }

        .details-box-title {
            padding-top: 20px;
            padding-bottom: 30px;

            p {
                font-family: $font-p;
                color: $second;
            }
        }

        .details-box-content{
            border-bottom: 1px solid #00000014;
            margin-right: 50px;
            padding-bottom: 30px;
            padding-top: 20px;
        
            .sub-header {
                font-family: $font-h;
                font-size: 1.1rem;
                padding-top: 10px;
                padding-bottom: 10px;
            }

            .sub-content {
                font-size: 0.9rem;
                color: $second;
                font-family: $font-p;

                ul {
                    list-style-type: none;
                }
            }

            .details-item {
                .edit {

                    a {
                    font-family: $font-h;
                    color: #000000;
                    font-size: 0.9rem;
                    font-weight: bold;
                    }
                }

                img {
                    object-fit: contain;
                    width: 100%;
                    border-radius: 6px;
                }
            }
            
        
        }
    }

    .navigator {
        padding: 50px 30px;
        margin-top: 10px;
        margin-bottom: 10px;
        border-left: 1px solid #00000014;
        position: sticky;
        top: 25%;

        .navigator-item:hover {
            background: aliceblue;
            border-radius: 6px;
        }

        .list-menu-item {
            font-family: $font-h;
            text-decoration: none;
            color:$second;
            font-size: 1.3rem;
            letter-spacing: 1px;
        }

        .list-menu-item-active {
            color: #000000;
            font-weight: bold;
        }
    }


}


.goback {
    button {
        display: flex;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: fit-content;
        font-size: 0.9rem;
        border: 1px solid;

        svg {
            font-size: 1.1rem;
        }
    }
}

.second {
    width: fit-content;
    font-size: 0.9rem;
}



.photo-edit, .areas-edit {
    margin-top: 50px;
    margin-bottom: 50px;
    border-bottom: 1px solid #00000014;

    .edit-header, .caption, .photo-action {
        padding-top: 10px;
        padding-bottom: 10px;

    }

    .caption {
        font-family: $font-p;
        font-size: 0.9rem;
    }



    .edit-image {
        padding: 30px;

        img {
            width: 100%;
            border-radius: 6px;
            object-fit: contain;
        }
    }

}

.photodialog {
    width: 500px;
    height: fit-content;
    padding: 50px 30px;
    position: relative;

    .closedialog {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;

        &:hover {
            color: #8c8c8c;
        }
    }

    

    .title {
        padding-top: 10px;
        padding-bottom: 20px;

    }

    .btn {
        input[type="file"] {
            position:absolute;
            top: -1000px;
        }

        label {
            
                width: 100%;
                padding: 7px;
                background: #fff;
                border: 2px solid rgba(0, 0, 0, 0.7);
                border-radius: 4px;
                color: rgba(0, 0, 0, 0.7);
                font-size: 0.9rem;
                font-family: $font-h;
                transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
            }
            
        label:hover {
            border: 2px solid #000;
            color: #000;
        }
        
        label:disabled {
            
            opacity: 0.5;
        
            &:hover {
                border: 2px solid rgba(0, 0, 0, 0.7);
                color: rgba(0, 0, 0, 0.7);
            }
            
        }
        
        label:active {
            border: 2px solid $blue;
            color: $blue;
        }
        
    }

    .no-image {
        width: 100%;
        text-align: center;
        border-style: dashed;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-color: #8c8c8c;
        color: #8c8c8c;
        font-size: 0.7rem;
        border-radius: 6px;
        font-family: $font-p;
    }


    .image {
        position: relative;
        img {
            width: 100%;
            height: 101px;
            object-fit: fill;
            border-radius: 6px;

        }

        .ok {
            position: absolute;
            top: -15px;
            right: -10px;
            cursor: pointer;

            &:hover {
                color: $blue;
            }
        }

        .cross {
            position: absolute;
            top: -15px;
            left: -10px;
            cursor: pointer;

            &:hover {
                color: #8c8c8c;
            }
        }
    }



}

.allphotos-upload {
    display: flex;
    justify-content: flex-end;
    padding: 30px;
}

.photos {
    padding-bottom: 30px;
    padding-top: 30px;
    padding-right: 30px;
}

.head-upload {
    position: sticky;
    top: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background: #fff;
    z-index: 100;
}

.photo-cover {
    position: relative;

    .photo-img {
        width: 100%;
        border-radius: 6px;
    }
}

.photo-cover:hover {
    .delete {
        opacity: 1;
    }
}


.sortable-ghost {
    img {

    box-shadow: 0px 1px 4px 1px #0000004f;
    
    }
}

.add-more-photos {
    width: 100%;
    min-height: 145px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 6px;
    border-style: dashed;
    border-color: #8c8c8c;
    flex-direction: column;

    div {
        font-family: $font-p;
        color: $second;
        font-weight: bold;
        font-size: 0.9rem;
    }
}

.delete {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    background: #fff;
    display: flex;
    padding: 6px;
    border-radius: 100%;
    box-shadow: 0px 0px 4px 0px #00000047;
    svg {
        font-size: 1.1rem;
        color: $second;
    }
}

.img-preview {
    margin-top: 40px;
    margin-bottom: 40px;
    .image {
        img {
            height: 100%;
        }
    }
}

.progress {
    margin-top: 10px;
}

.edit-box {
    padding: 40px 20px;
    border-radius: 6px;
    box-shadow: 0px 2px 5px -1px #0000001c;

    .click-out {
        cursor: pointer;
    }

    .edit-container-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .edit-box-content {
        margin-top: 25px;
        width: 80%;
        margin-bottom: 15px;
    }

    .caption {
        font-family: 'Open Sans';
        font-size: 0.7rem;
        color: #939393;
        position: relative;
        top: 15px;
    }

    .error {
        position: relative;
        top: 15px;
        font-family: $font-p;
        font-size: 0.7rem;
        color: $second;
    }


    .bottom-actions {
        margin-top: 40px;

        .cancel {
            background: #f7f7f7;
            border-radius: 30px;
            border: none;
        }

        .save {
            border-radius: 30px;
        }
    }
}


.edit {
    a {
        text-decoration: underline;
        cursor: pointer;
    }


}

.sub-content-description {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    display: -webkit-box;
}

#mui-rich-root {
    button {
        width: auto;
    }
}

.form-elements {
    textarea {
        width: 100%;
        resize: none;
        padding: 10px;
        font-family: 'Open Sans';
        border-radius: 6px;
        border: 1px solid $second;
    }

    textarea:focus {
        border: 2px solid $blue;
        
    }

    textarea:active {
        border: 2px solid;
    }
}

.areas-edit {
    margin-right: 50px;

    .item-area {
        padding-top: 15px;
        padding-bottom: 15px;
        margin-top: 25px;
        margin-bottom: 25px;

        .item-area-title {
            font-family: $font-p;
            color: $second;
            font-size: 1rem;
        }

        .action-btn {
            button {
                background: white;
                border: 2px solid;
                box-shadow: none;
                color: $blue;
            }
            div {
                text-align: center;
            }

            .item-area-num {
                font-family: $font-h;
                font-size: 1.2rem;
                color: $second;
                font-weight: bold;
            }
            
        }
    }
}

.item-beds {
    margin-top: 25px;
    margin-bottom: 25px;

    .bedroom {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        font-family: $font-p;
        font-size: 0.8rem;
        color: $second;
        border-bottom: 1px solid #e8e8e8;
    }
}


.btn-bedroom-add-head {
    margin-bottom: 12px;
}

.chip-element {
    button {
        width: initial;
    }
}

.item-area-title {
    font-family: "Open Sans", sans-serif;
    color: rgba(0, 0, 0, 0.7);
    font-size: 1rem;
}

.item-area-num {
    font-family: "Commissioner", sans-serif;
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.7);
    font-weight: bold;
}

.num-guests {
    button {
        background: white;
        border: 2px solid;
        box-shadow: none;
        color: #0072ff;
    }
}

.booking-details {
    margin-right: 80px;
    padding: 34px;
    box-shadow: 0px 2px 5px -1px #0000001c;

    label {
        color: rgba(0, 0, 0, 0.54);
        padding: 0;
        font-size: 1rem;
        font-family: Commissioner,sans-serif;
        font-weight: 400;
        line-height: 1;
        transform: translate(0, 1.5px) scale(0.75);
        transform-origin: top left;
        white-space: nowrap;
    }
} 

.booking-details-actions {
    margin-top: 40px;
    margin-bottom: 20px;
}

.custompricing-box {
    margin-top: 20px;
    padding: 32px;
    border: 1px solid #10389d24;
    border-radius: 6px;
}


.section-blocked-dates {
    font-family: 'Commissioner';
    font-size: 0.9rem;

    .booked {
        width: fit-content;
        background: #ffdeb4;
        padding: 3px 10px;
        font-family: 'Commissioner';
        border-radius: 2px;
        font-size: 0.9rem;
    }

    .blocked {
        width: fit-content;
        background: #585858;
        padding: 3px 10px;
        font-family: 'Commissioner';
        border-radius: 2px;
        font-size: 0.9rem;
        color: #fff;
    }

    .dates {
        width: fit-content;
        background-color: #0072ff;
        padding: 3px 10px;
        font-family: 'Commissioner';
        border-radius: 2px;
        font-size: 0.9rem;
        color: #fff;
    }

    button {
        padding: 10px 20px;
        font-size: 0.8rem;
        font-weight: bold;
        text-align: center;
        font-family: "Commissioner", sans-serif;
        display: flex;
        justify-content: center;
    }
}


.url-ota {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #0006c9;

    a {
        color: #0006c9;
    }
}

.price-preview {
    padding: 10px;
    border: 1px solid #5555;
    border-radius: 7px;
    .selector-preview {
        p {
            font-size: 1rem;
            font-family: 'Commissioner';
            font-weight: 400;
         }
        
    }
}

.roamhome-base-price {
    .roamhome-base-price-night {
        font-size: 1.5rem;
        font-family: 'Commissioner';
    }

    span {
        font-weight: 100;
        font-family: 'Open Sans';
        font-size: 0.8rem;
        color: #555;
    }
}

.preview-platform, .price-platform {
    font-family: 'Open Sans';
    font-size: 0.8rem;
    padding: 2px;
}

.preview-heading {
    font-weight: bold;
    border-bottom: 1px solid #5555550a;
}

.custom_periods {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #f2f2f2;
    .custom_period_header {
        font-family: 'Commissioner';
        background: #4b5cae;
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: bold;
        color: #fff;
    }

    .custom_period_item {
        margin: 5px 0px;
        padding: 5px 0px;
        background: #fbfbfb;
        border-bottom: 2px solid #a7b6ff;

        .date-item, .price-item {
            font-family: 'Open Sans';
            font-size: 0.7rem;
        }

        .remove-item {
            button {
                font-size: 0.7rem;
            }
        }
    }

}

.add-channel-manager {
    h5 {
        font-family: 'Commissioner';
    }

    .channel-manager-list {
        font-family: 'Commissioner';
        font-size: 0.7rem;

        button {
            font-size: 0.6rem;
        }
    }
}

.settings {
    margin-top: 20px;

    .settings-price {
        padding: 10px 20px;
        background: whitesmoke;
        border-radius: 4px;
        font-family: 'Open Sans';

        .span-settings-price {
            font-size: 18px;
        }

        .span-settings {
            font-size: 10px;
        }

    }
}

.custom-periods-items {
    margin-top: 20px;

    h4 {
        font-family: 'Commissioner';
    }

    .item-obj-period {
        margin-top: 10px;
        padding: 20px 0px;
        border-bottom: 2px solid #b4b4b461;

        .date-start, .date-end {
            padding: 6px 20px;
            background: #0072ff;
            color: #fff;
            border-radius: 4px;
            font-family: 'Commissioner';
            margin-right: 20px;
            font-size: 11px;
        }

        button {
            font-size: 12px;
        }

        .details-custom-period {
            font-family: 'Commissioner';
            font-size: 14px;
            color: #0072ff;

            .price-night {
                font-family: 'Commissioner';
                color: #747474;
                font-size: 14px;
            }
        }

    }
}
.dashboard-wrapper {
    padding: 50px 50px;

    .header-dashboard {
        padding: 25px;
        background: #f4f6ff;
        border-radius: 6px;
        font-family: $font-h;
        box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.10);

        img {
            width: 100%;
            height: 100px;
        }
    }

    .dashboard-indicator-wrapper {
        margin-top: 25px;
        margin-bottom: 25px;

        .dashboard-indicator {
            padding: 20px;
            box-shadow:0px 0px 3px 0px rgba(0, 0, 0, 0.13);
            border-radius: 6px;
            height: 100%;
        }

        .indicator-item {
            font-size: 1.5rem;
            color: $blue;
            font-family: $font-h;
        }

        .indicator-caption {
            font-family: $font-p;
            font-size: 0.8rem;
        }

    }
}

.tabs-wrapper-1 {
    margin-top: 50px;
    margin-bottom: 25px;

    .tabs {
        padding: 20px;
        border: 1px solid #e7ecff;
        border-radius: 6px;

        .tab-wrapper {
            margin-top: 20px;
            margin-bottom: 20px;

            .tab-head-switch {
                /*border: 2px solid $blue; */
                padding: 10px 10px;
                font-size: 0.8rem;
                font-weight: bold;
                /*color: $blue; */
                border-radius: 20px;
                text-align: center;
                font-family: $font-h;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .tab-head-switch-active {
                border: 2px solid $blue;
                color: $blue;
            }
        }


        .tab-content-wrapper {
            /*
            .control-booking {
                height: 420px;
                border-radius: 6px;
                overflow-y: auto;
                -webkit-box-shadow: inset 0px -2px 2px 1px rgba(0,0,0,0.1);
                -moz-box-shadow: inset 0px -2px 2px 1px rgba(0,0,0,0.1);
                box-shadow: inset 0px 0px 2px 0px rgba(0,0,0,0.1); 
            }

            */

            .booking-requests-item {
                margin: 5px;
                padding: 15px;
                border: 1px solid #f1f1f1;
                border-radius: 6px;
                box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.13);

                &:hover {
                    background: #fcfcfc;
                    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.13);
                }

                img {
                    border-radius: 6px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }


            }

            .booking-requests-item-title {
                font-weight: bold;
                font-size: 1.1rem;
                font-family: $font-p;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

            }

            
            .booking-date-item {
 

                .time-tile {
                    padding: 5px;
                    background: $blue;
                    color: #fff;
                    border-radius: 6px;
                    font-family: $font-p;
                    font-size: 0.8rem;
                }
            }
        }

        .load-more {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .tile {
            color: #8a8a8a;
            font-size: 0.8rem;
            margin-top: 4px;
            margin-bottom: 4px;
        }

        .booking-id {
            text-overflow: ellipsis;
            width: 100%;
            overflow: hidden;
            font-size: 0.7rem;
            font-weight: bold;
            font-family: 'Open Sans';
            color: #0072ff;

        }

        .tile-schema {
            
                font-family: $font-p;
                font-size: 0.7rem;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            
            
        }

        .approve, .reject {
            padding: 10px 20px;
            font-size: 0.8rem;
            font-weight: bold;
            border-radius: 20px;
            text-align: center;
            font-family: "Commissioner", sans-serif;
            display: flex;
            justify-content: center;
        }

        .approve:active {
            border: 2px solid #00bc20;
            color: #00bc20;
        }

        .reject:active {
            border: 2px solid red;
            color: red;
        }


    }
}

text {
    font-family: 'Open Sans' !important;
    fill: #000000 !important;
}


.graph {
    div {
        svg {
            height: 450px !important;
        }
    }
}

.control-booking {
    a {
        text-decoration: inherit;
        color: inherit;
    }
}

.contact-item {
    padding: 8px 4px;
    font-family: 'Commissioner';
    font-size: 0.9rem;
    border-bottom: 1px solid #eaeaea;

    &:hover {
        background: #fafafa;
    }

    .contact-date {
        font-size: 0.7rem;
        color: #7c7a7a;
    }
}

.bookingid {
    border: 1px solid #0072ff;
    color: #0072ff;
    padding: 2px;
    border-radius: 2px;
    border-style: dashed;
    cursor: pointer;
}

.refunded {
    padding: 2px;
    color: white;
    background: #06ba06;
    margin: 5px;
}

.refund_id {
    font-size: 0.6rem;
    margin: 5px;
    
}

.refund_amt {
    font-size: 0.6rem;
    margin: 5px;
    
}
.rs {
    font-family: Commissioner,sans-serif;
    font-size: 0.8rem;
}
.legen {
    color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 0.7rem;
    font-family: Commissioner,sans-serif;
    font-weight: 400;
    line-height: 1;
}
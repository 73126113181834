.fixed-padding2 {
    padding: 0px 10px;
}

.calendar-box2 {
    padding: 10px;
    box-shadow: 0px 2px 5px -1px #0000001c;
}

.top-board {
    border-bottom: 2px solid #e2e2e2;
}

.listing {
    .item2 {
        display: flex;
    }
}

.arrow {
    border: none;

    &:focus {
        border: none;
    }

    &:hover {
        border: none;
    }
}

.listing, .calendar-cover {
    height: 600px;
    overflow-y: scroll;
    
    .item2 {
        padding: 0px 0px;
        height: 30px;
        max-height: 30px;
        border-bottom: 2px solid #ededed;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        align-items: center;
        width: fit-content;

        img {
            border-radius: 2px;
        }

        .item-listing {
            width: auto;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: 'Commissioner';
            font-size: 0.7rem;
        }
    }
      

    .item {
        padding: 20px 10px;
        height: 70px;
        max-height: 70px;
        //border-bottom: 1px solid;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;

        .item-img {
            padding: 5px;

            img {
                border-radius: 4px;
            }
        }

        .item-listing {
            width: auto;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: 'Commissioner';
        }

        .pricing {
            padding: 1px 5px;
            background: #0072ff;
            width: fit-content;
            border-radius: 3px;
            color: white;
            font-family: 'Commissioner';

            span {
                font-size: 0.7rem;
            }
        }

    }

    .col {
       
        display: inline-block;
        width: 70px;
        text-align: center;
        border-left: 1px solid #e6e6e6;
        padding: 20px 10px;
        font-family: 'Open Sans';
        font-size: 0.9rem;
        color: #000000;
    }

    .col2 {
        display: inline-block;
        width: 70px;
        text-align: center;
        border-left: 1px solid #e6e6e6;
        padding: 7px 0px;
        font-family: 'Open Sans';
        font-size: 0.7rem;
        color: #fff;
        /* padding: 0px 2px; */
        width: 30px;
    }
}

.calendar-cover {
    overflow-x: overlay;
    .item {
    display: flex;
    overflow: initial;
    text-overflow: initial;
    width: fit-content;
    padding: 0px;

    .col {
            &:hover {
                background: #f6fdfd;
            }
        }
    }

    .custom-period {
        top: 15px;
        position: relative;
        left: 0;

        .custom {
            position: absolute;
            /* width: 70px; */
            text-overflow: ellipsis;
            white-space: nowrap;
            min-width: 70px;
            overflow-x: hidden;
            /* left: 35px; */
            top: 0;
            background: #002977;
            height: 15px;
            transform: skewX(-45deg);
        }


        
    }


    .custom-period2 {
        top: 20px;
        position: relative;
        left: 0;

        .custom2 {
            position: absolute;
            /* width: 70px; */
            text-overflow: ellipsis;
            white-space: nowrap;
            min-width: 30px;
            overflow-x: hidden;
            /* left: 35px; */
            top: 0;
            background: #676767;
            height: 10px;
            transform: skewX(-45deg);
        }
    }

    .events {
        position: relative;
        top: -30px;
        left: 0;

        .event {
            position: absolute;
            width: 70px;
            text-overflow: ellipsis;
            white-space: nowrap;
            min-width: 70px;
            overflow-x: hidden;
            left: 35px;
            top: 0;
            background: #0057ff;
            height: 59px;
            border: 1px solid;
            transform: skewX(-45deg);
        }

        .event2 {
            position: absolute;
            width: 30px;
            text-overflow: ellipsis;
            white-space: nowrap;
            min-width: 30px;
            overflow-x: hidden;
            left: 15px;
            top: 30px;
            background: #0057ff;
            height: 30px;
            border: 1px solid;
            transform: skewX(-45deg);
        }

        .event-info {
            transform: skewX(45deg);
            display: flex;
            width: 100%;
            height: 100%;
            overflow: hidden;
            align-items: center;
            padding: 10px;
            margin-left: 15px;

            .event-object {
                display: flex;
                align-items: center;

                .event-details {
                    padding: 6px 12px;
                    margin-left: 15px;
                    color: white;
                    font-family: 'Commissioner';
                    font-weight: bold;
                }
            }
        }
    }
}

.calendar-box {
    padding: 34px;
    box-shadow: 0px 2px 5px -1px #0000001c;
}

.property-title-calendar {
    font-family: 'Commissioner';
    padding: 6px;
    background: #47607e;
    color: white;
    font-weight: bold;
}

.property-title-calendar2 {
    font-family: 'Commissioner';
    padding: 8px;
    background: #47607e;
    color: white;
    font-weight: bold;
    font-size: 0.8rem;
}

.listing {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    .item:hover {
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);

    }
    
    &::-webkit-scrollbar {
        display: none;
      }
}

.title-calendar-cover {
    overflow: hidden;
   .title-item {
    height: fit-content;
    border: none;
    display: flex;
    overflow: initial;
    text-overflow: initial;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0px;
    overflow-y: scroll;
    .col {
        display: inline-block;
        width: 70px;
        text-align: center;
        border: 1px solid #47607e;
        padding: 5px 10px;
        font-family: 'Open Sans';
        font-size: 0.9rem;
        color: #47607e;
        background: white;
        font-weight: bold;

        
    }

    .col2 {
        display: inline-block;
        width: 30px;
        text-align: center;    
        border: 1px solid #efefef;
        padding: 0px 2px;
        font-family: 'Open Sans';
        font-size: 0.7rem;
        color: #47607e;
        background: white;
        font-weight: bold;
    }

    

    .today {
        background: #0072ff;
        color: #fff;
        border: 1px solid #0072ff;
    }
   }
}
.calendar-cover {
.events {
    .roamhome-blocked {
        background: #2c2c2cee;
        border: none;
    }

    .roamhome {
        background: #e54135ee;
        border: none;
    }

    .airbnb {
        background: #e51e5dee;
        border: none;
    }

    .bookingcom {
        background: #003580e0;
        border: none;
    }
}
}

.wrap {
    
    width: 500px;
    padding: 15px;
    .dialog-close {
        height: fit-content;
        width: fit-content;
        border: none;
    }

    p {
        font-family: 'Open Sans';
    }

    .blocked-timeline, .custom-timeline {
        background: #404040;
        color: #fff;
        padding: 15px;
        font-family: 'Commissioner';
        border-radius: 4px;
        margin-bottom: 20px;
    }

    .custom-timeline {
        background: #11004e;
    }

    .pricing {
        padding: 1px 5px;
        background: #0072ff;
        width: -moz-fit-content;
        width: fit-content;
        border-radius: 3px;
        color: white;
        font-family: 'Commissioner';

        span {
            font-size: 0.7rem;
        }
    }
}
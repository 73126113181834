$font-h: 'Commissioner', sans-serif;
$font-p: 'Open Sans', sans-serif;
$blue: #0072ff;
$dark-blue: #02326d;
$fixed-padding: 10px 20px;
$second: rgba(0, 0, 0, 0.7);

* {
    margin: 0;
    outline: 0;
    padding: 0;
    box-sizing: border-box;
}

h1, h2, h3, h4 {
    font-family: $font-h;
}



.fixed-padding {
    padding: $fixed-padding;
}

button {
    width: 100%;
    padding: 7px;
    background: #fff;
    border: 2px solid rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.7);
    font-size: 1.1rem;
    font-weight: bold;
    font-family: $font-h;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

button:hover {
    border: 2px solid #000;
    color: #000;
}

button:disabled {
   
    opacity: 0.5;

    &:hover {
        border: 2px solid rgba(0, 0, 0, 0.7);
        color: rgba(0, 0, 0, 0.7);
    }
    
}

button:active {
    border: 2px solid $blue;
    color: $blue;
}

.button-type-2 {
    padding: 10px 40px;
    font-size: 0.8rem;
    font-weight: bold;
    text-align: center;
    font-family: "Commissioner", sans-serif;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.MuiIconButton-root:focus, .MuiIconButton-root:active, .MuiIconButton-root:hover {
    border: none !important;
}



.MuiIconButton-root:hover {
    color:#0073ffe8;
}

.MuiCollapse-wrapperInner {


    .primary-notification {
        background-color: $blue !important;
        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
    }

}

.empty {
    margin-top: 50px;
    margin-bottom: 50px;
    img {
        object-fit: contain;
        width: 100%;
        height: 100px;
    }

     p {
        text-align: center;
        font-size: 1.1rem;
        color: $second;
        font-family: $font-h;
    }
}

/* All imports in a logic order */

@import './header.scss';
@import './footer.scss';
@import './static.scss';
@import './auth/auth.scss';
@import './dashboard-landing/dashboard-landing.scss';
@import './listings/listings.scss';
@import './manage-listing/manage-listing.scss';
@import './performance/performance.scss';
@import './reservations/reservations.scss';
@import './booking-request/booking-request.scss';
@import './reservation-detail/reservation-detail.scss';
@import './manage-draft/manage-draft.scss';
@import './calendar/calendar.scss';
@import './calendar/test/calendar-test.scss';
@import './manage-misc/booking-sheet.scss';
@import './manage-misc/payout-sheet.scss';
@import './manage-misc/manage-misc.scss';
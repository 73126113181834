$cell-width: 60px;
$cell-height: 75px;
$cell-border-width: 1px;
$cell-border-color: #ececec;
$input-color: rgb(190, 190, 190);
$component-border-color: rgb(215, 215, 215);

.calendar-test {
    .cell {
        font-family: $font-h;
        height: $cell-height;
        min-width: $cell-width;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-right: $cell-border-width solid $cell-border-color;
        border-bottom: $cell-border-width solid $cell-border-color;

        /*&::after{
            content: '';
            display: block;
            box-sizing: border-box;
            position: absolute;
            top:0px;
            left:0px;
            height: $cell-height;
            width: $cell-width;
            z-index: 250;
            background-color:transparent;
            border-right: $cell-border-width solid $cell-border-color;
            border-bottom: $cell-border-width solid $cell-border-color;
        }*/

    }

    .top-ribbon {
        margin-bottom: 3px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }

    .calendar {

        border-bottom: 1px solid $component-border-color;

        &-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        &-select {
            background-color: #F5F5F5;
            border-radius: 5px;
            padding: 10px 8px 10px 8px;
        }

        &-select-input {

            border: none !important;
            padding: 0 !important;
        }

        &-select::before {
            border: none !important;
            outline: none;
        }

        &-btn {
            height: $cell-height;
            border: none;
            display: flex;
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;
            cursor: pointer;
        }


    }

    .calendar-dialog {
        width: 500px;
        padding: 15px;

        .dialog-btn-close {
            border: none;
            cursor: pointer;
        }

        .blocked-dates {
            background: #404040;
            color: #fff;
            padding: 15px;
            font-family: 'Commissioner';
            border-radius: 4px;
            margin-bottom: 20px;
        }

        .blocked-content {
            font-family: 'Open Sans';
        }

        .pricing {
            padding: 1px 5px;
            background: #0072ff;
            width: -moz-fit-content;
            width: fit-content;
            border-radius: 3px;
            color: white;
            font-family: 'Commissioner';

            span {
                font-size: 0.7rem;
            }
        }

        .booking-sample {
            padding: 20px 10px;
            margin-top: 20px;
            margin-bottom: 10px;
            border-radius: 6px;
            box-shadow: 0px 2px 5px -1px #0000001c;

            .booking-id {
                max-width: fit-content;
                background: white;
                text-transform: uppercase;
                padding: 6px;
                font-weight: bold;
                font-family: 'Open Sans';
                color: #0072ff;
            }

            .booking-status,
            .booking-source {
                max-width: fit-content;
                text-transform: uppercase;
                padding: 6px;
                font-weight: bold;
                font-family: 'Open Sans';
                color: #ffffff;
                border-radius: 5px;
            }

            .section {
                margin-top: 10px;
                border-top: 1px solid #f2f2f2;
                padding-top: 10px;
                font-family: 'Open Sans';
                font-size: 0.85rem;

                .sub-section {
                    margin-top: 20px;
                }
            }

            .time-line {
                background: #0072ff;
                color: #fff;
                font-weight: bold;
                padding: 6px 10px;
                border-radius: 3px;
            }
        }
    }

    .timeline {

        &-row {
            position: relative;
            display: flex;
            overflow: hidden;
            flex-wrap: nowrap;
        }

        &-select {
            background-color: #F5F5F5;
            border-radius: 5px;
            padding: 10px 8px 10px 8px;
            border-bottom: none !important;
            cursor: pointer;


            /*.MuiFilledInput-underline::before {
                border: none !important;
            }

            .MuiFilledInput-underline:after {
                border: none;
            }
*/
            input {
                font-weight: bold;
                text-align: center;
                padding: 3px;
                background-color: #F5F5F5;
                border: none;
            }

            input::after {
                border: none !important;
            }
        }

        &-select::before {
            border: none !important;
        }

        &-btn {
            border-radius: 0;
            border: none;
            position: sticky;
            height: $cell-height;
            min-width: $cell-width;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            cursor: pointer;
            z-index: 275;

            &-left {
                @extend .timeline-btn;
                left: 0;
            }

            &-right {
                @extend .timeline-btn;
                right: 0;
            }
        }

    }

    .listing {

        &-timeline {
            position: relative;
            //margin-bottom: 3px;

            overflow: hidden;

            &-row {
                display: flex;
                flex-wrap: nowrap;
                position: relative;
                //overflow: hidden;
            }
        }

        &-name {
            position: absolute;
            left: 0;
            top: 0;
            margin-top: 5px;
            padding-left: 40px;
            padding-bottom: 7px;
            font-family: $font-h;
            font-weight: bold;

        }

        &-cell {
            cursor: pointer;
            min-width: $cell-width;
            //height: calc($cell-height + 20px);
            border-bottom: none;

            .cell {
                background-color: #FFFAF7;

                &:hover {
                    background-color: #f3f3f3;
                }
            }

            &-active {
                position: relative;

                &::after {
                    pointer-events: none;
                    box-sizing: border-box;
                    display: block;
                    content: '';
                    background-color: transparent;
                    position: absolute;
                    z-index: 300;
                    top: 0px;
                    left: 0px;
                    width: 60px;
                    height: 105px;
                    border-right: 1px solid #c2c2c2;
                    border-left: 1px solid #c0c0c0;
                }
            }
        }

        .cell-content {
            color: #989898;
        }

        .cell-padding {
            height: 30px;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 30px;
                background: transparent;
                width: 60px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 3px 0px 0px;
            }
        }
    }

    .col {
        display: flex;
        flex-direction: column;
    }

    .day-active {
        border: none;
        border-bottom: 3px solid orange;
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(246, 127, 61, 0.36207986612613796) 100%);
        background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(246, 127, 61, 0.36207986612613796) 100%);
        background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(246, 127, 61, 0.36207986612613796) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#f67f3d", GradientType=1);
    }

    .filter {
        justify-content: space-evenly;
        min-width: 300px;
        padding-left: 30px;
        padding-right: 30px;
        border-bottom: 1px solid $component-border-color;
        border-right: 1px solid rgb(215, 215, 215);
        font-family: $font-h;
        font-weight: bold;

        &-property {
            font-size: 0.75rem;
            border: 2px solid rgb(190, 190, 190);
            padding: 0.75rem;
            border-radius: 5px;
        }

        &-icon {
            color: rgb(190, 190, 190);
        }

        .property-search {
            padding: 10px 8px 10px 8px;
        }
    }

    .period {
        top: 30px;
        position: absolute;
        height: $cell-height;
        padding: 1.5px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-family: $font-h;
        box-sizing: border-box;
        z-index: 200;
        cursor: pointer;

        &-body {
            width: 100%;
        }

        &-icon {
            padding: 3px;

            &-success {
                font-size: 10px;
                color: #63d88e;
            }
        }

        &-content {
            overflow: hidden;
            white-space: nowrap;
            padding-left: 0px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        }

        &-content>span {
            text-overflow: ellipsis;
            min-width: 25px;
            overflow: hidden;
        }

        &-customer-name {
            font-weight: bold;
            font-size: 12px;
        }

        &-range {
            font-size: 10px;
        }

        &-price {
            font-weight: bold;
            font-size: 14px;
        }
    }

    .period-roamhome {
        @extend .period;

        .period-body {
            background-color: #FFE7E7ff;
            border: 2px solid #ff000050;
            border-radius: 5px;
            height: 100%;
            display: flex;

        }
    }

    .period-airbnb {
        @extend .period;

        .period-body {
            background-color: #f5d0f5ff;
            border: 2px solid #ff56ffeb;
            border-radius: 5px;
            height: 100%;
            display: flex;
        }
    }


    .period-blocked {
        @extend .period;
        padding-left: 0;
        padding-right: 0;
        z-index: 100;

        .period-body {
            height: 100%;
            display: flex;

            .period-blocked-icon {
                height: 100%;
                width: 61px;
                background:
                    linear-gradient(to top left,
                        rgba(0, 0, 0, 0) 0%,
                        rgba(0, 0, 0, 0) calc(50% - 1px),
                        rgba(152, 152, 152, 1) 50%,
                        rgba(0, 0, 0, 0) calc(50% + 1px),
                        rgba(0, 0, 0, 0) 100%),
                    linear-gradient(to top right,
                        rgba(0, 0, 0, 0) 0%,
                        rgba(0, 0, 0, 0) calc(50% - 1px),
                        rgba(152, 152, 152, 1) 50%,
                        rgba(0, 0, 0, 0) calc(50% + 1px),
                        rgba(0, 0, 0, 0) 100%);
            }
        }


    }

    .period-booking_dot_com {
        @extend .period;

        .period-body {
            background-color: rgb(193, 208, 249);
            border: 2px solid #1a33b1;
            border-radius: 5px;
            height: 100%;
            display: flex;
        }
    }


    .period-goibibo {
        @extend .period;

        .period-body {
            background-color: rgb(205, 242, 211);
            border: 2px solid #1ab151;
            border-radius: 5px;
            height: 100%;
            display: flex;
        }
    }
}
.booking-sample {
    padding: 20px 10px;
    margin-top: 20px;
    margin-bottom: 10px;
    border-radius: 6px;
    box-shadow: 0px 2px 5px -1px #0000001c;

    .booking-id {
        max-width: fit-content;
        background: white;
        text-transform: uppercase;
        padding: 6px;
        font-weight: bold;
        font-family: 'Open Sans';
        color: #0072ff;
    }

    .booking-status {
        max-width: fit-content;
        text-transform: uppercase;
        padding: 6px;
        font-weight: bold;
        font-family: 'Open Sans';
        color: #ffffff;
        border-radius: 5px;
    }

    .section {
        margin-top: 10px;
        border-top: 1px solid #f2f2f2;
        padding-top: 10px;
        font-family: 'Open Sans';
        font-size: 0.85rem;

        .sub-section {
            margin-top: 20px;
        }
    }

    .payment-order-id {
        text-transform: uppercase;
        padding: 0px 9px;
        font-weight: bold;
        font-family: 'Open Sans';
        background: #eaf4ff;
    }

    .time-line {
        background: #0072ff;
        color: #fff;
        font-weight: bold;
        padding: 6px 10px;
        border-radius: 3px;
    }

    .bill-header {
        font-size: 0.9rem;
        font-weight: bold;
        color: #5d5d5d;
        font-family: 'Commissioner';
    }

    .prop-title {
        
        color: #0072ff;
    }

    .bill-body {
        padding-bottom: 15px;
        margin-bottom: 5px;
        border-bottom: 1px solid #ededed;
    }

    .transactions {
        margin-top: 30px;
        margin-bottom: 30px;
        font-family: 'Open Sans';
        font-size: 0.75rem;

        .title {
            font-size: 0.9rem;
            font-weight: bold;
            color: #5d5d5d;
            font-family: 'Commissioner';
            margin-bottom: 9px;
        }
    }
}

.customer-feedback {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px dashed #0072ff;
    .customer-feedback-header {
        font-weight: bold;
        font-family: 'Commissioner';
    }

    .customer-rated {
        padding: 30px;
        margin: 5px;
        text-align: center;

        .overall-caption-rating {
            font-size: 0.8rem;
            font-family: 'Commissioner';
        }

        .overall-rating {
            font-size: 2rem;
            font-family: 'Open Sans';
            color: #787171;
        }
    }

    ul li {
        font-family: 'Commissioner';
        font-size: 0.8rem;
        list-style-type: none;
    }
} 

.what-user-thinks {
    .q {
        font-weight: bold;
        font-family: 'Commissioner';
    }

    p {
        font-family: 'Commissioner';
         font-size: 0.8rem;
    }
}

